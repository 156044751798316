export const useFormatText = text => {
  const spacedText = text
    .replace(/\]/gm, "] ")
    .replace(/\[/gm, " [")
    .replace(/\s\s/gm, " ")
    .trim();
  const textArray = spacedText.split(" ").map(text => {
    if (text.match(/^\[size=\d{1,2}\]$/gm)) {
      const size = text.match(/\d{1,2}/);
      return `<span style="font-size:${size}px">`;
    } else if (text === "[/size]") {
      return "</span>";
    } else if (text === "[b]") {
      return `<span style="font-weight:bold">`;
    } else if (text === "[/b]") {
      return "</span>";
    } else {
      return text;
    }
  });
  return textArray;
};
