import React from "react";
import styled from "styled-components";
import { bool, string, number } from "prop-types";
import { useTranslation, Trans } from "@lib/i18n";

import Typography from "@components/shared/Typography";

import { getFormattedPrice } from "@shared/v2/lib/getFormattedPrice";
import { useIsB2BSelected } from "@hooks/useIsB2BSelected";
import { useIsFree2MoveEnabled } from "@hooks/useIsFree2MoveEnabled";
import { withTabNavigation } from "@hoc/withTabNavigation";

import dynamic from "next/dynamic";

const ReactTooltip = dynamic(() => import("react-tooltip"), {
  ssr: false,
});

import {
  color,
  tablet,
  desktop,
  fontSize,
  fontWeight,
  font,
  tabletLarge,
  smallMobile,
  largeMobile,
  prop,
} from "@components/shared/utils";
import InfoIcon from "components/shared/Icons/InfoIcon";

const Info = styled.div`
  font-size: 0.6rem;
  font-weight: ${fontWeight("regular")};
  margin-bottom: 0.5rem;
  text-align: ${prop("textAlign")};
  margin-right: ${prop("marginRight")};
`;

const PriceContainer = styled.div`
  display: flex;
`;

const BasePriceText = styled(Typography)`
  color: ${color("white")};
  font-family: ${font("citroen")};
  font-weight: ${fontWeight("bold")};
  padding-right: 1rem;
  line-height: 100%;
`;

const PriceText = styled(BasePriceText)`
  font-size: ${fontSize("lg")};

  ${largeMobile`
    font-size: ${fontSize("xl")};
  `}
`;

const PricePerVehicleText = styled(BasePriceText)`
  font-size: ${fontSize("md")};

  ${largeMobile`
    font-size: ${fontSize("lg")};
  `}
`;

const TaxTagBase = styled(Typography)`
  color: ${color("white")};
  font-family: ${font("citroen")};
  line-height: 100%;

  padding-top: 0.3rem;
  text-transform: uppercase;
`;

const TaxTag = styled(TaxTagBase)`
  font-size: ${fontSize("xs")};

  ${smallMobile`
    font-size: ${fontSize("sm")};
  `}

  ${largeMobile`
    font-size: ${fontSize("md")};
  `}
`;

const TaxTagSmall = styled(TaxTagBase)`
  font-size: ${fontSize("xs")};

  ${smallMobile`
    font-size: ${fontSize("sm")};
  `}
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  z-index: 1;
  color: ${color("white")};

  ${tablet`
    margin-right: -0.2rem;
    min-width: 15rem;
    padding: 0;
  `}

  ${tabletLarge`
    margin-right: 0.2rem;
    min-width: 12rem;
  `}

  ${desktop`
    margin-right: 0;
  `}
`;

const Prices = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: ${({ isUIv2 }) => (!isUIv2 ? "0.5rem" : 0)};

  & > div {
    &:first-child:not(:last-child) > ${PriceContainer} {
      border-right: 1px solid ${color("white")};
      padding-right: 1rem;
      margin-right: 1rem;
    }
  }
`;

const AdditionalInfo = styled.div``;

const AdditionalText = styled(Typography)`
  font-family: ${font("citroen")};
  font-weight: ${fontWeight("bolder")};
  letter-spacing: 0.6px;
  font-size: 0.6rem;
  display: block;
  color: ${color("white")};
  text-align: right;
  cursor: default;
  text-decoration: ${({ isClickable }) => isClickable && "underline"};

  &:hover {
    cursor: ${({ isClickable }) => isClickable && "pointer"};
  }
`;

const AdditionalTextWithTabNavigation = withTabNavigation(AdditionalText);

const OTRWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  margin-left: -0.5em;
`;

const IconWrapper = styled.div`
  margin-left: -0.5em;
  padding-bottom: 0.2em;
  display: inline-flex;
  color: ${prop("color")};
`;

const CashPriceDetail = ({
  amount,
  currencySymbol,
  priceFormat,
  withDeliveryInfo,
  isQuantityVisible,
  vehicleQuantity,
  isUIv2 = false,
}) => {
  const { t, i18n } = useTranslation();

  const isB2BSelected = useIsB2BSelected();
  const isFree2MoveEnabled = useIsFree2MoveEnabled();

  const isB2CCashLinkPresent =
    !isB2BSelected &&
    Boolean(t("price.b2c.cashLink.url")) &&
    Boolean(t("price.b2c.cashLink.label"));

  const isB2BCashLinkPresent =
    isB2BSelected && isFree2MoveEnabled
    Boolean(t("price.b2b.cashLink.url")) &&
    Boolean(t("price.b2b.cashLink.label"));

  return (
    <ContentContainer data-id="price-switcher-price-container">
      <Prices isUIv2={isUIv2}>
        {isQuantityVisible && (
          <div>
            <Info textAlign="right" marginRight="2rem">
              {t("price.pricePerVehicle")}
            </Info>
            <PriceContainer>
              <PricePerVehicleText data-id="price-switcher-formatted-price-per-vehicle">
                {getFormattedPrice(amount, priceFormat, currencySymbol)}
              </PricePerVehicleText>
              {i18n.exists("price.vat") && (
                <TaxTagSmall>{t("price.vat")}</TaxTagSmall>
              )}
            </PriceContainer>
          </div>
        )}
        <div>
          {isQuantityVisible && (
            <Info>
              {t("price.allVehiclesTotal", { count: vehicleQuantity })}
            </Info>
          )}
          <PriceContainer>
            <PriceText data-id="price-switcher-formatted-price">
              {getFormattedPrice(
                isQuantityVisible ? amount * vehicleQuantity : amount,
                priceFormat,
                currencySymbol
              )}
            </PriceText>
            {isUIv2 && (
              <>
                <OTRWrapper>
                  <PriceText>{t("price.otr")}</PriceText>
                  <IconWrapper color="white" data-tip data-for="otrtooltip">
                    <InfoIcon size={16} color={"black"} />
                  </IconWrapper>

                  <ReactTooltip id="otrtooltip" effect="solid">
                    <div style={{ whiteSpace: "normal", maxWidth: 220 }}>
                      {t("price.otrTooltip")}
                    </div>
                  </ReactTooltip>
                </OTRWrapper>
              </>
            )}
            {!isUIv2 && i18n.exists("price.vat") && (
              <TaxTag>{t("price.vat")}</TaxTag>
            )}
          </PriceContainer>
        </div>
      </Prices>
      <AdditionalInfo>
        {!isUIv2 && withDeliveryInfo && (
          <AdditionalText data-id="price-switcher-deliveryinfo-text">
            <Trans>{t("price.deliveryInfo.priceDetail")}</Trans>
          </AdditionalText>
        )}
        {(isB2CCashLinkPresent || isB2BCashLinkPresent) && (
          <AdditionalTextWithTabNavigation
            data-id="price-switcher-b2b-cash-link"
            onClick={() =>
              window.open(
                isB2BSelected
                  ? t("price.b2b.cashLink.url")
                  : t("price.b2c.cashLink.url"),
                "_blank"
              )
            }
            isClickable
          >
            <Trans>
              {isB2BSelected
                ? t("price.b2b.cashLink.label") || t("price.b2b.cashLink.url")
                : t("price.b2c.cashLink.label") || t("price.b2c.cashLink.url")}
            </Trans>
          </AdditionalTextWithTabNavigation>
        )}
      </AdditionalInfo>
    </ContentContainer>
  );
};

CashPriceDetail.propTypes = {
  amount: number.isRequired,
  currencySymbol: string.isRequired,
  priceFormat: string.isRequired,
  withTitle: bool,
  withDeliveryInfo: bool,
  isQuantityVisible: bool,
  vehicleQuantity: number,
  isUIv2: bool,
};

export default CashPriceDetail;
