import React from "react";
import styled, { css } from "styled-components";
import { bool, string, number } from "prop-types";
import { useTranslation, Trans } from "@lib/i18n";

import Typography from "@components/shared/Typography";

import { getFormattedPrice } from "@shared/v2/lib/getFormattedPrice";
import { useIsB2BSelected } from "@hooks/useIsB2BSelected";

import {
  color,
  tablet,
  desktop,
  fontSize,
  fontWeight,
  font,
  largeMobile,
  tabletLarge,
} from "@components/shared/utils";
import { useIsSCFJourneySelected } from "hooks/useIsSCFJourneySelected";

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 100%;
  justify-content: center;
  padding: 0.5rem;
  z-index: 1;
  color: ${color("white")};
  min-width: ${({ isSFC }) => (isSFC ? "7rem" : "12rem")};
  margin-right: 0.5rem;
  position: relative;

${({ withFinanceWidgetButton }) =>
  withFinanceWidgetButton &&
  css`
    margin-right: 0.5rem;

    &:after {
      background: ${color("grey")};
      content: "";
      height: 60%;
      position: absolute;
      right: -0.25rem;
      top: 20%;
      width: 1px;

      ${tablet`
        right: -0.75rem;
      `}
    }
  `}

  ${tablet`
    padding: 1rem;
  `}

  ${tabletLarge`
    padding: 0.4rem;
  `}

  ${desktop`
    min-width: ${({ isSFC }) => (isSFC ? "8rem" : "15rem")};
  `}
`;

const StyledSup = styled.sup`
  font-size: ${fontSize("xs")};
  padding-left: 0.3rem;

  ${largeMobile`
    font-size: ${fontSize("sm")};
  `}
`;

const PriceInformationContainer = styled.div`
  font-family: ${font("citroen")};
  font-weight: ${fontWeight("bolder")};
  letter-spacing: 0.6px;
  padding-top: 0.2rem;
  font-size: 0.6rem;
  display: inline-block;
  color: ${color("white")};
  text-align: right;
`;

const PriceText = styled(Typography)`
  font-family: ${font("citroen")};
  color: ${color("white")};
  font-size: ${fontSize("lg")};
  font-weight: ${fontWeight("bold")};

  ${tablet`
    font-size: ${fontSize("xl")};
  `}

  ${tabletLarge`
    font-size: ${fontSize("lg")};
  `}
  ${desktop`
    font-size: ${fontSize("xl")};
  `}
`;

const StyledQuantity = styled.span`
  font-size: ${fontSize("md")};
  > span {
    font-size: 0.6rem;
    font-weight: ${fontWeight("bolder")};
  }
`;

const DepositText = styled(Typography)`
  color: ${color("white")};
  ${StyledQuantity} {
    font-size: ${fontSize("xs")};
  }
`;

const ErrorMessage = styled(Typography)`
  color: ${color("white")};
  font-family: ${font("citroen")};
  font-size: ${fontSize("md")};
  font-weight: ${fontWeight("bold")};
  margin: auto;
  max-width: 20rem;
  padding: 0 1rem;
  text-align: center;
`;

const AdditionalText = styled(Typography)`
  font-family: ${font("citroen")};
  font-weight: ${fontWeight("bolder")};
  letter-spacing: 0.6px;
  font-size: 0.6rem;
  display: block;
  color: ${color("white")};
  text-align: right;
  cursor: default;
  text-decoration: ${({ isClickable }) => isClickable && "underline"};

  &:hover {
    cursor: ${({ isClickable }) => isClickable && "pointer"};
  }
`;

const FinancePriceDetail = ({
  isFinanceRequestError,
  amount,
  deposit,
  priceFormat,
  currencySymbol,
  withDeliveryInfo,
  withFinanceWidgetButton,
  isQuantityVisible,
  vehicleQuantity,
}) => {
  const { t } = useTranslation();

  const isB2BSelected = useIsB2BSelected();
  const isSCFJourneySelected = useIsSCFJourneySelected();

  const formattedAmount = getFormattedPrice(
    amount,
    priceFormat,
    currencySymbol
  );

  return (
    <>
      {isFinanceRequestError ? (
        <ErrorMessage>{t("financeDataErrorMessage")}</ErrorMessage>
      ) : (
        <ContentContainer
          data-id="price-switcher-price-container"
          isSFC={isSCFJourneySelected}
          withFinanceWidgetButton={withFinanceWidgetButton}
        >
          <PriceText amount={amount} data-id="price-switcher-formatted-price">
            {isQuantityVisible && vehicleQuantity > 1 && (
              <StyledQuantity>
                {vehicleQuantity} <span>x</span>{" "}
              </StyledQuantity>
            )}
            {formattedAmount}{" "}
            <StyledSup>
              {isB2BSelected ? t("price.b2b.month") : t("price.b2c.month")}
            </StyledSup>
          </PriceText>
          <PriceInformationContainer>
            {Boolean(deposit) && (
              <DepositText bold size="sm" data-id="price-switcher-deposit-text">
                {isQuantityVisible && vehicleQuantity > 1 && (
                  <StyledQuantity>
                    {vehicleQuantity} <span>x</span>{" "}
                  </StyledQuantity>
                )}
                {t("price.afterFirstPayment", {
                  downPayment: getFormattedPrice(
                    Math.ceil(deposit),
                    priceFormat,
                    currencySymbol
                  ),
                })}
              </DepositText>
            )}

            {withDeliveryInfo && (
              <AdditionalText data-id="price-switcher-deliveryinfo-text">
                <Trans>{t("price.deliveryInfo.priceDetail")}</Trans>
              </AdditionalText>
            )}
          </PriceInformationContainer>
        </ContentContainer>
      )}
    </>
  );
};

FinancePriceDetail.propTypes = {
  priceFormat: string.isRequired,
  isFinanceRequestError: bool,
  amount: number,
  deposit: number,
  currencySymbol: string.isRequired,
  withDeliveryInfo: bool,
  withFinanceWidgetButton: bool,
  isQuantityVisible: bool,
  vehicleQuantity: number,
};

export default FinancePriceDetail;
