import React from "react";
import { bool } from "prop-types";

import BaseIcon from "../BaseIcon";

const Icon = props => (
  <BaseIcon viewBox="0 0 26 26" {...props}>
    <path
      // fill="none"
      // stroke="currentColor"
      data-name="calculator-svg"
      d="M22.336,5.28H9.664A1.634,1.634,0,0,0,8.032,6.912V24.448A1.634,1.634,0,0,0,9.664,26.08H22.336a1.634,1.634,0,0,0,1.632-1.632V6.912A1.634,1.634,0,0,0,22.336,5.28Zm.032,19.168a.032.032,0,0,1-.032.032H9.664a.032.032,0,0,1-.032-.032V6.912a.032.032,0,0,1,.032-.032H22.336a.032.032,0,0,1,.032.032ZM11.912,13.84h1.824v1.824H11.912Zm0,3.344h1.824v1.824H11.912Zm0,3.344h1.824v1.824H11.912Zm3.176-6.688h1.824v1.824H15.088Zm0,3.344h1.824v1.824H15.088Zm0,3.344h1.824v1.824H15.088Zm3.176-6.688h1.824v1.824H18.264Zm0,3.344h1.824v5.168H18.264ZM11.912,9.16h8.176v2.7H11.912Z"
    />
    <rect
      // fill="none"
      // stroke="currentColor"
      data-name="calculator-svg_solid"
      width="32"
      height="32"
      opacity="0"
    />
  </BaseIcon>
);

Icon.propTypes = {
  checked: bool,
};

export default Icon;
