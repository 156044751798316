import React, { useState } from "react";
import styled, { css } from "styled-components";
import { bool, string, number } from "prop-types";
import { useTranslation } from "@lib/i18n";

import Typography from "@components/shared/Typography";
import FinanceTerms from "@components/shared/FinanceTerms";

import { getFormattedPrice } from "@shared/v2/lib/getFormattedPrice";

import {
  color,
  tablet,
  desktop,
  fontSize,
  fontWeight,
  font,
  tabletLarge,
} from "@components/shared/utils";

import { useVACPriceBreakdownSelector } from "@redux/reducers/deal";
import { withTabNavigation } from "@hoc/withTabNavigation";

const formatText = (text, quantity) => {
  return text && text.replace(/\[(\/)?b\]/g, "").replace("{0}", quantity);
};

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 100%;
  justify-content: center;
  z-index: 1;
  color: ${color("white")};
  position: relative;

  ${({ withFinanceWidgetButton }) =>
    withFinanceWidgetButton &&
    css`
    &:after {
      /* background: ${color("grey")}; */
      content: "";
      height: 60%;
      position: absolute;
      right: -0.25rem;
      top: 20%;
      width: 1px;

      ${tablet`
        right: -0.75rem;
      `}
    }
  `}

  ${tabletLarge`
    ${({ isBasketUi }) =>
      isBasketUi &&
      css`
        padding-top: 0;
        align-items: flex-start;
      `}
  `}
`;

const PriceText = styled.div`
  font-family: ${font("citroen")};
  color: ${color("white")};
  font-size: ${fontSize("lg")};
  font-weight: ${fontWeight("bold")};
  margin-top: ${({ isBasketUi }) => (isBasketUi ? "1rem" : 0)};

  ${tablet`
    font-size: ${fontSize("xl")};
  `}

  ${tabletLarge`
    font-size: ${fontSize("lg")};
  `}
  ${desktop`
    font-size: ${fontSize("xl")};
  `};
`;

const PriceInfoText = styled.span`
  font-size: ${fontSize("xs")};
  text-transform: none;
`;

const PriceRepresentative = styled(Typography)`
  font-family: ${font("citroen")};
  color: ${color("white")};
  font-size: ${fontSize("xs")};
  font-weight: ${fontWeight("bold")};
  text-transform: none;
  line-height: 0.9375rem;

${({ link }) =>
  link &&
  css`
    cursor: pointer;
    text-decoration: underline;
  `})}
`;

const PriceRepresentativeWithTabNavigation = withTabNavigation(
  PriceRepresentative
);

const StyledQuantity = styled.span`
  font-size: ${fontSize("md")};
  > span {
    font-size: 0.6rem;
    font-weight: ${fontWeight("bolder")};
  }
`;

const ErrorMessage = styled(Typography)`
  color: ${color("white")};
  font-family: ${font("citroen")};
  font-size: ${fontSize("md")};
  font-weight: ${fontWeight("bold")};
  margin: auto;
  max-width: 20rem;
  padding: 0 1rem;
  text-align: center;
`;

const VACPriceDetailv2 = ({
  isFinanceRequestError,
  amount,
  priceFormat,
  currencySymbol,
  withFinanceWidgetButton,
  isQuantityVisible,
  vehicleQuantity,
  apr,
  isBasketUi,
  isPersonalised,
}) => {
  const [isFinanceTermsOpen, setIsFinanceTermsOpen] = useState(false);

  const { t } = useTranslation();

  const breakdown = useVACPriceBreakdownSelector();

  const getTeaser = () => breakdown?.vehicleSimulation?.result?.teaser;

  const formattedAmount = () =>
    getFormattedPrice(amount, priceFormat, currencySymbol);

  const aprWithPercentage = apr?.toString().includes("%") ? apr : `${apr}%`;

  return (
    <>
      {isFinanceRequestError ? (
        <ErrorMessage>{t("financeDataErrorMessage")}</ErrorMessage>
      ) : (
        <ContentContainer
          withFinanceWidgetButton={withFinanceWidgetButton}
          data-id="price-switcher-price-container"
          isBasketUi={isBasketUi}
        >
          <PriceText amount={amount} data-id="price-switcher-formatted-price">
            {isQuantityVisible && vehicleQuantity > 1 && (
              <StyledQuantity>
                {vehicleQuantity} <span>x</span>{" "}
              </StyledQuantity>
            )}
            {!isPersonalised && (
              <PriceInfoText>{t("price.vac.startingFrom")}</PriceInfoText>
            )}
            {formattedAmount()}
            <PriceInfoText>{t("price.vac.perMonth")}</PriceInfoText>
            <PriceInfoText>*</PriceInfoText>
            <PriceRepresentative>
              {formatText(
                getTeaser()?.primarytext?.[0].text,
                aprWithPercentage
              )}
            </PriceRepresentative>
            <PriceRepresentativeWithTabNavigation
              onClick={() => setIsFinanceTermsOpen(true)}
              link
            >
              {formatText(getTeaser()?.callToAction.detailText)}
            </PriceRepresentativeWithTabNavigation>
          </PriceText>
          <FinanceTerms
            isOpen={isFinanceTermsOpen}
            onClose={() => setIsFinanceTermsOpen(false)}
          />
        </ContentContainer>
      )}
    </>
  );
};

VACPriceDetailv2.propTypes = {
  priceFormat: string.isRequired,
  isFinanceRequestError: bool,
  withEcoBonus: bool,
  amount: number,
  deposit: number,
  currencySymbol: string.isRequired,
  withDeliveryInfo: bool,
  withFinanceWidgetButton: bool,
  isQuantityVisible: bool,
  vehicleQuantity: number,
  isUIv2: bool,
  apr: number,
  isBasketUi: bool,
  isPersonalised: bool,
};

export default VACPriceDetailv2;
