import React, { useMemo, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { bool, number, string, func } from "prop-types";
import { useTranslation } from "@lib/i18n";

import Typography from "@components/shared/Typography";
import CashPriceDetail from "./CashPriceDetail";
import FinancePriceDetail from "./FinancePriceDetail";
import VACPriceDetailv2 from "./VACPriceDetailv2";
import Parallelograms from "./Parallelograms";
import JourneySwitch from "./JourneySwitch";
import FinanceWidgetButton from "./FinanceWidgetButton";
import FinanceWidgetButtonUIv2 from "./FinanceWidgetButtonUIv2";
import CircularLoader from "@components/shared/Icons/CircularLoader";
import LOAPriceDetail from "@components/PriceDetail/LOAPriceDetail";
import VACPriceDetail from "@components/PriceDetail/VACPriceDetail";

import { getIsCashJourneySelected } from "@shared/v2/lib/getIsCashJourneySelected";
import { getIsLOAJourneySelected } from "@shared/v2/lib/getIsLOAJourneySelected";
import { getIsVACJourneySelected } from "@shared/v2/lib/getIsVACJourneySelected";
import { financeGatewayTeaserType } from "@types";
import { useIsJourneySwitchEnabled } from "@hooks/useIsJourneySwitchEnabled";
import { useIsUIv2Selector } from "@redux/reducers/appConfig";

import {
  color,
  fontSize,
  fontWeight,
  font,
  desktop,
  tabletLarge,
  smallMobile,
  tablet,
} from "@components/shared/utils";
import { getIsCountry, COUNTRIES } from "lib/getIsCountry";
import { getIsSCFJourneySelected } from "@shared/v2/lib/getIsSCFJourneySelected";
import { useSCFPriceBreakdownSelector } from "redux/reducers/deal";
import LegalMentionModal from "components/Financing/LegalMentionModal";

const WrapperContainer = styled.div`
  background: ${color("black1")};
  border-radius: 0.4rem;
  position: relative;
  overflow: hidden;
  display: flex;

  ${desktop`
    margin-right: 0;
  `}
`;

const TotalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${({ isBasketUi }) =>
    isBasketUi &&
    smallMobile`
      display: flex;
      align-items: center;
    `};
`;

const PricesSection = styled.div`
  display: flex;
  flex: 1 1 100%;
  align-items: center;
  flex-direction: row;
  padding: ${({ withParallelograms, isBasketUi }) =>
    withParallelograms && !isBasketUi ? "0 3rem 0 0" : 0};

  ${({ isBasketUi }) =>
    (isBasketUi || getIsCountry(COUNTRIES.GB)) &&
    css`
      flex-direction: column;
      justify-content: flex-start;
      row-gap: 1rem;

      ${({ isBasketUi }) =>
        !isBasketUi &&
        `
        align-items: center;
      `}

      ${tabletLarge`
        flex-direction: row;
        align-items: flex-end;
      `}

      ${({ isBasketUi }) =>
        isBasketUi &&
        css`
          ${desktop`
          flex-direction: column;
          row-gap: 0;
        `}
        `}
    `}

  ${tabletLarge`
    align-items: center;
    column-gap: 1rem;
  `}
`;

const MobileJourneySwitch = styled(JourneySwitch)`
  ${tablet`
    display: none !important;
  `}
`;

const DesktopJourneySwitch = styled(JourneySwitch)`
  display: none;

  ${tablet`
    display: flex;
  `}
`;

const Container = styled.div`
  align-items: center;
  background: ${color("black1")};
  display: flex;
  font-weight: 700;
  left: 0;
  justify-content: space-between;
  width: 100%;
  top: 0;
  z-index: 1;
  flex-direction: column;
  position: relative;
  padding: 1rem;
  row-gap: 1rem;

  ${tablet`
    flex-direction: row;
    column-gap: 1rem;

    & > ${PricesSection} {
      align-self: center;
    }
  `}

  ${tabletLarge`
    position: initial;
  `}
`;

const Title = styled(Typography)`
  color: ${color("white")};
  font-family: ${font("citroen")};
  font-size: ${fontSize("lg")};
  font-weight: ${fontWeight("bold")};
  padding-left: 1rem;
  text-transform: uppercase;
`;

const PriceDetailContainer = styled.div`
  flex: 1;
`;

const CircularLoaderContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
`;

const StyledCircularLoader = styled(CircularLoader)`
  color: ${color("white")};
  height: 3rem;
  width: 3rem;
`;

const StyledParallelograms = styled(Parallelograms)`
  position: absolute;
  right: 0;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;

  ${tablet`
    justify-content: flex-end;
  `}
`;

const Divider = styled.div`
  margin: 0 1rem;
  border-right: 1px solid ${color("white")};
  height: auto;

  ${tablet`
    margin: 0 1.325rem;
  `}

  ${desktop`
    margin: 0 0.625rem;
  `}
`;

const SCFWrap = styled.div`
  color: ${color("white")};
  display: flex;
  font-size: ${fontSize("xs")};
  font-weight: ${fontWeight("bold")};
  gap: 1rem;
  text-transform: uppercase;
  columns: 2;
`;
const SCFColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  justify-content: center;
`;

const SCFRow = styled.div`
  line-height: 16px;

  ${tablet`
    white-space: nowrap;
  `}
`;

const SCFLegalMentionButton = styled.button`
  appearance: none;
  background: ${color("white")};
  border: 0;
  border-radius: 50%;
  color: ${color("black1")};
  cursor: pointer;
  font-style: italic;
  font-family: serif;
  font-size: 14px;
  height: 16px;
  padding: 0;
  width: 16px;

  &:hover {
    background: ${color("brightOrange")};
    color: ${color("white")};
  }
`;

const PriceDetail = ({
  isFinanceRequestError,
  journey,
  className,
  amount,
  total = amount,
  deposit,
  currencySymbol,
  priceFormat,
  isLoading,
  withTitle,
  withEcoBonus,
  withJourneySwitch,
  withParallelograms,
  withFinanceWidgetButton,
  withDeliveryInfo,
  teaser,
  onIsFinancingOpenChange,
  vehicleQuantity,
  isQuantityVisible,
  forceFinancingSwitch = false,
  isBasketUi,
  isPersonalisedVACOffer,
}) => {
  const { t } = useTranslation();

  const containerRef = useRef(null);

  const [isLegalMentionVisible, setIsLegalMentionVisible] = useState(false);

  const isCashJourneySelected = getIsCashJourneySelected(journey);
  const isLOAJourneySelected = getIsLOAJourneySelected(journey);
  const isVACJourneySelected = getIsVACJourneySelected(journey);
  const isSCFJourneySelected = getIsSCFJourneySelected(journey);

  const scfBreakdown = useSCFPriceBreakdownSelector();

  const isUIv2 = useIsUIv2Selector();

  const isPriceSwitcherEnabled = useIsJourneySwitchEnabled();

  const isPriceSwitcherVisible =
    (!isUIv2 || forceFinancingSwitch) &&
    isPriceSwitcherEnabled &&
    withJourneySwitch;

  const isPriceInitialized =
    Number.isFinite(amount) || isLoading || isFinanceRequestError;

  const getContent = () => {
    if (isLoading || (!isCashJourneySelected && !isPriceInitialized)) {
      return (
        <CircularLoaderContainer>
          <StyledCircularLoader />
        </CircularLoaderContainer>
      );
    }

    if (isCashJourneySelected) {
      return (
        <CashPriceDetail
          withTitle={!withJourneySwitch}
          withEcoBonus={withEcoBonus}
          withDeliveryInfo={withDeliveryInfo}
          amount={amount}
          currencySymbol={currencySymbol}
          priceFormat={priceFormat}
          isQuantityVisible={isQuantityVisible}
          vehicleQuantity={vehicleQuantity}
          isUIv2={isUIv2}
        />
      );
    }

    if (isLOAJourneySelected) {
      return (
        <LOAPriceDetail
          withFinanceWidgetButton={withFinanceWidgetButton}
          amount={amount}
          teaser={teaser}
        />
      );
    }

    if (isVACJourneySelected) {
      if (isUIv2) {
        return (
          <Row>
            <TotalContainer isBasketUi={isBasketUi}>
              <CashPriceDetail
                withTitle={!withJourneySwitch}
                withEcoBonus={withEcoBonus}
                withDeliveryInfo={withDeliveryInfo}
                amount={total}
                currencySymbol={currencySymbol}
                priceFormat={priceFormat}
                isQuantityVisible={isQuantityVisible}
                vehicleQuantity={vehicleQuantity}
                isUIv2={isUIv2}
              />
            </TotalContainer>
            <Divider isBasketUi={isBasketUi} />
            <VACPriceDetailv2
              isFinanceRequestError={isFinanceRequestError}
              withTitle={!withJourneySwitch}
              withEcoBonus={withEcoBonus}
              withDeliveryInfo={withDeliveryInfo}
              amount={amount}
              deposit={deposit}
              apr={teaser?.data.apr}
              priceFormat={priceFormat}
              currencySymbol={currencySymbol}
              withFinanceWidgetButton={withFinanceWidgetButton}
              isQuantityVisible={isQuantityVisible}
              vehicleQuantity={vehicleQuantity}
              isPersonalised={isPersonalisedVACOffer}
              isUIv2={isUIv2}
              isBasketUi={isBasketUi}
            />
          </Row>
        );
      }

      return (
        <Row>
          <VACPriceDetail
            isFinanceRequestError={isFinanceRequestError}
            withTitle={!withJourneySwitch}
            withEcoBonus={withEcoBonus}
            withDeliveryInfo={withDeliveryInfo}
            amount={amount}
            total={total}
            priceFormat={priceFormat}
            currencySymbol={currencySymbol}
            withFinanceWidgetButton={withFinanceWidgetButton}
            onIsFinancingOpenChange={onIsFinancingOpenChange}
            teaser={teaser}
          />
        </Row>
      );
    }

    return (
      <FinancePriceDetail
        isFinanceRequestError={isFinanceRequestError}
        withTitle={!withJourneySwitch}
        withEcoBonus={withEcoBonus}
        withDeliveryInfo={withDeliveryInfo}
        amount={amount}
        deposit={deposit}
        priceFormat={priceFormat}
        currencySymbol={currencySymbol}
        withFinanceWidgetButton={withFinanceWidgetButton}
        isQuantityVisible={isQuantityVisible}
        vehicleQuantity={vehicleQuantity}
      />
    );
  };

  const scfLegalMention =
    scfBreakdown?.vehicleSimulation?.result?.globalText?.legalMention;

  const scfData = useMemo(() => {
    if (!isSCFJourneySelected || !scfBreakdown?.vehicleSimulation?.result) {
      return false;
    }

    const {
      displayBlocks,
      globalText: { financialProductLabel },
    } = scfBreakdown.vehicleSimulation.result;
    const lines = displayBlocks[0].displayLines;

    return [
      // column 1
      [
        t("price.scf.monthly", { amount: lines[0].displayValue }),
        lines[1].displayValue,
        t("price.scf.taeg", { amount: lines[2].displayValue }),
      ],
      // column 2
      [
        t("price.scf.initialPayment", { amount: lines[5].displayValue }),
        t("price.scf.financedAmount", { amount: lines[6].displayValue }),
        <>
          {financialProductLabel.text}{" "}
          <SCFLegalMentionButton onClick={() => setIsLegalMentionVisible(true)}>
            i
          </SCFLegalMentionButton>
        </>,
        t("price.deliveryInfo.priceDetail"),
      ],
    ];
  }, [isSCFJourneySelected, scfBreakdown]);

  return (
    <WrapperContainer className={className} ref={containerRef}>
      <Container>
        {withTitle && isCashJourneySelected && (
          <Title as="h3">{t("price.yourCashPrice")}</Title>
        )}
        {withTitle &&
          !isCashJourneySelected &&
          t("price.yourFinancePrice") !== "" && (
            <Title as="h3">{t("price.yourFinancePrice")}</Title>
          )}
        {isPriceSwitcherVisible && (
          <>
            <MobileJourneySwitch isHorizontal isUIv2={isUIv2} />
            <DesktopJourneySwitch isUIv2={isUIv2} />
          </>
        )}
        <PricesSection
          withParallelograms={withParallelograms && !scfData}
          isBasketUi={isBasketUi}
        >
          {isSCFJourneySelected && !!scfData ? (
            <SCFWrap>
              <SCFColumn>
                {scfData[0].map((item, idx) => (
                  <SCFRow key={idx}>{item}</SCFRow>
                ))}
              </SCFColumn>
              <SCFColumn>
                {scfData[1].map((item, idx) => (
                  <SCFRow key={idx}>{item}</SCFRow>
                ))}
              </SCFColumn>
              <LegalMentionModal
                isOpen={isLegalMentionVisible}
                legalMention={scfLegalMention}
                onClose={() => setIsLegalMentionVisible(false)}
              />
            </SCFWrap>
          ) : (
            <>
              <PriceDetailContainer>{getContent()}</PriceDetailContainer>
              {!isUIv2 && withFinanceWidgetButton && <FinanceWidgetButton />}
              {isUIv2 && withFinanceWidgetButton && (
                <FinanceWidgetButtonUIv2 isBasketUi={isBasketUi} />
              )}
            </>
          )}
        </PricesSection>
        {withParallelograms && !scfData && !isUIv2 && <StyledParallelograms />}
      </Container>
    </WrapperContainer>
  );
};

PriceDetail.propTypes = {
  journey: string.isRequired,
  amount: number,
  currencySymbol: string.isRequired,
  priceFormat: string.isRequired,
  onIsFinancingOpenChange: func.isRequired,
  isFinanceRequestError: bool,
  deposit: number,
  className: string,
  isLoading: bool,
  withTitle: bool,
  withEcoBonus: bool,
  withJourneySwitch: bool,
  withParallelograms: bool,
  withFinanceWidgetButton: bool,
  withDeliveryInfo: bool,
  teaser: financeGatewayTeaserType,
  isQuantityVisible: bool,
  vehicleQuantity: number,
  forceFinancingSwitch: bool,
  total: number,
  isBasketUi: bool,
  isPersonalisedVACOffer: bool,
};

export default PriceDetail;
