import React from "react";
import styled from "styled-components";
import { bool, func } from "prop-types";
import { Trans, useTranslation } from "@lib/i18n";

import Typography from "../shared/Typography";
import Modal from "../shared/Modal";

import gtm from "@gtm/core";
import { linkEcologicalBonusClickEvent } from "@gtm/events/homepage";

const StyledModal = styled(Modal)`
  padding: 1rem 2rem;
`;

const StyledDiv = styled.div`
  word-wrap: break-word;
  word-break: break-word;
`;

const EcoBonusModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation();

  const ecoBonusLink = t("price.ecoBonusLink");
  const ecoBonusAdditionalLink = t("price.ecoBonusAdditionalLink");

  const clickRedirect = link => {
    gtm.fire(
      linkEcologicalBonusClickEvent({
        label: link,
      })
    );
  };

  return (
    <StyledModal
      isOpen={isOpen}
      withCloseIcon
      ariaLabel={t("price.ecologicalBonus")}
      onClose={onClose}
    >
      <Typography size="sm" margin="0.5rem 0">
        {ecoBonusLink && (
          <Trans i18nKey="price.ecologicalBonusModalInfo">
            <a
              href={ecoBonusLink}
              target="_blank"
              onClick={() => clickRedirect(ecoBonusLink)}
              rel="noopener noreferrer"
            >
              {{ link: ecoBonusLink }}
            </a>
            <a
              href={ecoBonusLink}
              target="_blank"
              onClick={() => clickRedirect(ecoBonusLink)}
              rel="noopener noreferrer"
            >
              {{ linkLabel: t("price.ecoBonusLinkLabel") }}
            </a>
            <a
              href={ecoBonusAdditionalLink}
              target="_blank"
              onClick={() => clickRedirect(ecoBonusAdditionalLink)}
              rel="noopener noreferrer"
            >
              {{
                additionalLinkLabel1: t("price.ecoBonusAdditionalLinkLabel1"),
              }}
            </a>
            <a
              href={ecoBonusAdditionalLink}
              target="_blank"
              onClick={() => clickRedirect(ecoBonusAdditionalLink)}
              rel="noopener noreferrer"
            >
              {{
                additionalLinkLabel2: t("price.ecoBonusAdditionalLinkLabel2"),
              }}
            </a>
          </Trans>
        )}

        {!ecoBonusLink && (
          <StyledDiv
            dangerouslySetInnerHTML={{
              __html: t("price.ecologicalBonusModalInfo"),
            }}
          />
        )}
      </Typography>
    </StyledModal>
  );
};

EcoBonusModal.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
};

export default EcoBonusModal;
