import { useIsB2BSelected } from "@hooks/useIsB2BSelected";
import { getIsB2BEnabledForAnyJourney } from "@shared/v2/lib/getIsB2BEnabledForAnyJourney";
import { isConfigurationAvailable } from "@shared/helper/isConfigurationAvailable";
import { useActiveCarPreconfigurationSelector } from "@redux/reducers/deal";

import {
  useIsJourneySwitchEnabledSelector,
  useIsB2BJourneySwitchEnabledSelector,
  useConfigurationSelector,
} from "@redux/reducers/appConfig";

export const useIsJourneySwitchEnabled = () => {
  const configuration = useConfigurationSelector();
  const isB2BSelected = useIsB2BSelected();
  const isB2BEnabled = getIsB2BEnabledForAnyJourney(configuration);

  const isB2BJourneySwitchEnabled = useIsB2BJourneySwitchEnabledSelector();
  const isJourneySwitchEnabled = useIsJourneySwitchEnabledSelector();

  const preconfiguration = useActiveCarPreconfigurationSelector();

  if (!isB2BEnabled || !isB2BSelected) {
    return (
      isJourneySwitchEnabled &&
      isConfigurationAvailable(false)(preconfiguration)
    );
  }

  return (
    isB2BJourneySwitchEnabled &&
    isConfigurationAvailable(true)(preconfiguration)
  );
};
