import React, { useState, useRef, useEffect } from "react";
import styled, { css } from "styled-components";
import { bool, string, number, func } from "prop-types";
import { useTranslation, Trans } from "@lib/i18n";

import Typography from "@components/shared/Typography";
import EcoBonusModal from "./EcoBonusModal";
import { FINANCING_COLLAPSABLE_CONTAINER } from "@components/Financing/Financing";

import { getFormattedPrice } from "@shared/v2/lib/getFormattedPrice";
import { financeGatewayTeaserType } from "@types";
import { useScrollToView } from "@hooks/useScrollToView";
import { useFormatText } from "@hooks/useFormatText";
import { useFinanceGatewayDataParser } from "hooks/useFinanceGatewayDataParser";
import { withTabNavigation } from "@hoc/withTabNavigation";

import {
  color,
  tablet,
  desktop,
  fontSize,
  fontWeight,
  font,
  tabletLarge,
} from "@components/shared/utils";

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 100%;
  justify-content: center;
  padding: 0.5rem;
  z-index: 1;
  color: ${color("white")};
  min-width: 12rem;
  margin-right: 0.5rem;
  position: relative;

${({ withFinanceWidgetButton }) =>
  withFinanceWidgetButton &&
  css`
    margin-right: 0.5rem;

    ${tablet`
      margin-right: 1.5rem;
    `}

    &:after {
      background: ${color("grey")};
      content: "";
      height: 60%;
      position: absolute;
      right: -0.25rem;
      top: 20%;
      width: 1px;

      ${tablet`
        right: -0.75rem;
      `}
    }
  `}

  ${tablet`
    padding: 1rem;
  `}

  ${tabletLarge`
    padding: 0.4rem;
  `}

  ${desktop`
    padding: 1rem 0 1rem 1rem;
    min-width: 16rem;
  `}
`;

const PriceInformationContainer = styled.div`
  font-family: ${font("citroen")};
  font-weight: ${fontWeight("bolder")};
  letter-spacing: 0.6px;
  padding-top: 0.2rem;
  font-size: 0.6rem;
  display: inline-block;
  color: ${color("white")};
  text-align: right;
`;

const StartingFromContainer = styled.div`
  display: flex;
  flex-flow: column;
  align-items: baseline;
  font-family: ${font("citroen")};
  color: ${color("white")};
  font-size: ${fontSize("sm")};
  font-weight: ${fontWeight("bold")};

  ${desktop`
    flex-flow: row;
  `}
`;

const StartingFromText = styled(Typography)`
  line-height: 100%;
  vertical-align: bottom;
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
`;

const StyledSup = styled.sup`
  font-size: ${fontSize("xs")};
  font-size: ${fontWeight("regular")};
  color: inherit;
`;

const PriceContainer = styled.div`
  display: flex;

  ${tablet`
    flex-direction: row;
  `}
`;

const PriceText = styled(Typography)`
  font-family: ${font("citroen")};
  color: ${color("white")};
  font-size: ${fontSize("lg")};
  font-weight: ${fontWeight("bold")};
  line-height: 100%;
  padding-left: 0.3rem;

  ${tablet`
    padding-left: 0.5rem;
  `}

  ${desktop`
    font-size: ${({ amount }) =>
      amount < 100 ? fontSize("lg") : fontSize("md")};
  `}
`;

const DepositText = styled(Typography)`
  color: ${color("white")};
  font-size: ${fontSize("xs")};

  > span {
    display: block;
    font-size: ${fontSize("default")};
  }
`;

const ErrorMessage = styled(Typography)`
  color: ${color("white")};
  font-family: ${font("citroen")};
  font-size: ${fontSize("md")};
  font-weight: ${fontWeight("bold")};
  margin: auto;
  max-width: 20rem;
  padding: 0 1rem;
  text-align: center;
`;

const AdditionalText = styled(Typography)`
  font-family: ${font("citroen")};
  font-weight: ${fontWeight("bolder")};
  letter-spacing: 0.6px;
  font-size: 0.6rem;
  display: block;
  color: ${color("white")};
  text-align: right;
`;

const AdditionalTextLink = withTabNavigation(styled(Typography)`
  font-family: ${font("citroen")};
  font-weight: ${fontWeight("bolder")};
  letter-spacing: 0.6px;
  font-size: 0.6rem;
  color: ${color("white")};
  cursor: default;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
`);

const VACPriceDetail = ({
  isFinanceRequestError,
  withEcoBonus,
  amount,
  priceFormat,
  currencySymbol,
  withFinanceWidgetButton,
  onIsFinancingOpenChange,
  teaser,
}) => {
  const { t } = useTranslation();
  const [isEcoBonusModalOpen, setIsEcoBonusModalOpen] = useState(false);
  const financeGatewayDataParser = useFinanceGatewayDataParser({});

  const formattedAmount = () =>
    getFormattedPrice(amount, priceFormat, currencySymbol);

  const scrollToView = useScrollToView();

  const financingCollapsableContainerRef = useRef(null);

  useEffect(() => {
    financingCollapsableContainerRef.current = document.getElementById(
      FINANCING_COLLAPSABLE_CONTAINER
    );
  }, []);

  return (
    <>
      {isFinanceRequestError ? (
        <ErrorMessage>{t("financeDataErrorMessage")}</ErrorMessage>
      ) : (
        <ContentContainer
          withFinanceWidgetButton={withFinanceWidgetButton}
          data-id="price-switcher-price-container"
        >
          <StartingFromContainer>
            <StartingFromText>{t("price.vac.startingFrom")}</StartingFromText>
            <PriceContainer>
              <PriceText
                amount={amount}
                data-id="price-switcher-formatted-price"
              >
                {formattedAmount()}
              </PriceText>
              <StyledSup>{t("price.b2c.month")}</StyledSup>
            </PriceContainer>
          </StartingFromContainer>
          <PriceInformationContainer>
            {financeGatewayDataParser("primarytext", teaser).map(
              ({ parsedText }, index) => (
                <DepositText
                  bold
                  key={index}
                  dangerouslySetInnerHTML={{
                    __html: useFormatText(parsedText).join(" "),
                  }}
                />
              )
            )}
          </PriceInformationContainer>
          <AdditionalText data-id="price-switcher-deliveryinfo-text">
            <Trans>{t("price.vac.monthlyFee")}</Trans>
            {t("price.vac.openDetail") && t("price.vac.openDetail").length > 0 && (
              <AdditionalTextLink
                onClick={() => {
                  onIsFinancingOpenChange?.(true);

                  scrollToView(financingCollapsableContainerRef);
                }}
              >
                {t("price.vac.openDetail")}
              </AdditionalTextLink>
            )}
          </AdditionalText>
        </ContentContainer>
      )}
      {withEcoBonus && (
        <EcoBonusModal
          isOpen={isEcoBonusModalOpen}
          onClose={() => setIsEcoBonusModalOpen(false)}
        />
      )}
    </>
  );
};

VACPriceDetail.propTypes = {
  priceFormat: string.isRequired,
  currencySymbol: string.isRequired,
  isFinanceRequestError: bool,
  withEcoBonus: bool,
  amount: number,
  deposit: number,
  withDeliveryInfo: bool,
  withFinanceWidgetButton: bool,
  onIsFinancingOpenChange: func,
  teaser: financeGatewayTeaserType,
};

export default VACPriceDetail;
