import React from "react";
import styled, { useTheme } from "styled-components";
import { useTranslation } from "@lib/i18n";
import { useDispatch } from "react-redux";
import { bool, string } from "prop-types";

import Typography from "@components/shared/Typography";
import Switch from "@components/shared/Switch";
import TextSwitch from "@components/shared/TextSwitch";
import gtm from "@gtm/core";

import {
  resetDeliveryData,
  updateCarConfigurationAction,
  updateJourneyAction,
} from "@redux/actions/dealActions";
import { useIsCashJourneySelected } from "@hooks/useIsCashJourneySelected";
import { useIsLeasingJourneySelected } from "@hooks/useIsLeasingJourneySelected";
import { useIsJourneySwitchActive } from "@hooks/useIsJourneySwitchActive";

import {
  useSelectedJourneySelector,
  useActiveCarPreconfigurationSelector,
} from "@redux/reducers/deal";
import { changeFundingEvent } from "@gtm/events/shared";

import { getAvailableJourneys } from "@shared/helper/getAvailableJourneys";
import { useConfigurationSelector } from "redux/reducers/appConfig";

import { withTabNavigation } from "@hoc/withTabNavigation";

import {
  desktop,
  font,
  fontWeight,
  smallMobile,
  tablet,
  tabletLarge,
} from "@components/shared/utils";
import { CUSTOMIZE_TYPES } from "@shared/constants";

const OptionSwitcherWrapper = styled("div")`
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 0.2rem;
  padding: 0.5rem 0;
  opacity: ${({ isJourneySwitchActive }) => (isJourneySwitchActive ? 1 : 0.5)};
  pointer-events: ${({ isJourneySwitchActive }) =>
    isJourneySwitchActive ? "inherit" : "none"};

  ${smallMobile`
    margin-left: 2rem;
    padding: 1rem 0;
  `}

  ${tabletLarge`
    margin-left: 0rem;
  `}

  ${desktop`
    transform: scale(1.3);
    margin-left: 1rem;
    margin-right: 10px;
  `}
`;

const HorizontalOptionSwitcherWrapper = styled("div")`
  display: flex;
  align-items: center;
  opacity: ${({ isJourneySwitchActive }) => (isJourneySwitchActive ? 1 : 0.5)};
  pointer-events: ${({ isJourneySwitchActive }) =>
    isJourneySwitchActive ? "inherit" : "none"};

  & > * {
    flex-shrink: 0;
  }

  & > *:nth-child(2) {
    margin: 0 2rem;
  }
`;

const SwitchLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0.5rem;

  ${desktop`
    margin-right: 0.5rem;
  `}
`;

const SwitchLabel = styled(Typography)`
  color: ${({ isActive, theme }) =>
    isActive ? theme.colors.white : theme.colors.semiTransparentWhite};

  cursor: pointer;
  font-family: ${font("citroen")};
  font-weight: ${fontWeight("bold")};
  transition: color 0.3s ease-out;
  user-select: none;
  font-size: 0.8rem;
  &:first-child {
    margin-bottom: ${({ isHorizontal }) => (isHorizontal ? 0 : "0.5rem")};
  }

  ${({ isUIv2 }) =>
    !isUIv2
      ? ""
      : smallMobile`
    white-space: nowrap;
    font-size: 0.8rem;
    line-height: 1.5rem;
  `}

  ${tablet`
    font-size: 0.7rem;
  `}
`;

const SwitchLabelWrapper = withTabNavigation(styled.div``);

const JourneySwitch = ({
  isHorizontal,
  className,
  isTextVersion = false,
  isUIv2 = false,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const preconfiguration = useActiveCarPreconfigurationSelector();
  const isJourneySwitchActive = useIsJourneySwitchActive(preconfiguration);
  const isCashJourneySelected = useIsCashJourneySelected();
  const isLeasingJourneySelected = useIsLeasingJourneySelected();

  const selectedJourney = useSelectedJourneySelector();
  const configuration = useConfigurationSelector();

  const setJourneyType = nextJourney => {
    dispatch(updateJourneyAction(nextJourney));

    gtm.fire(changeFundingEvent(nextJourney));
  };

  const toggleJourneyType = () => {
    if (isJourneySwitchActive) {
      dispatch(resetDeliveryData());
      dispatch(updateCarConfigurationAction({ [CUSTOMIZE_TYPES.SERVICE]: [] }));

      const availableJourneys = getAvailableJourneys(configuration);

      const nextJourneyType = availableJourneys.find(
        journey => journey !== selectedJourney
      );

      setJourneyType(nextJourneyType);
    }
  };

  if (isTextVersion) {
    return (
      <HorizontalOptionSwitcherWrapper
        className={className}
        data-id="price-switcher-input-toggle"
        isJourneySwitchActive
      >
        <TextSwitch
          selectedText={
            isUIv2 ? t("price.monthlyPayments") : t("price.monthly")
          }
          unselectedText={isUIv2 ? t("price.singlePayment") : t("price.cash")}
          checked={isCashJourneySelected}
          onChange={toggleJourneyType}
          switchBorderColor={theme.colors.white}
        />
      </HorizontalOptionSwitcherWrapper>
    );
  }

  if (isHorizontal) {
    return (
      <HorizontalOptionSwitcherWrapper
        className={className}
        data-id="price-switcher-input-toggle"
        isJourneySwitchActive={isJourneySwitchActive}
      >
        <SwitchLabelWrapper>
          <SwitchLabel
            as="label"
            isActive={isLeasingJourneySelected}
            onClick={toggleJourneyType}
            isHorizontal
          >
            {isUIv2 ? t("price.monthlyPayments") : t("price.monthly")}
          </SwitchLabel>
        </SwitchLabelWrapper>
        <Switch
          checked={isCashJourneySelected}
          onChange={toggleJourneyType}
          switchBorderColor={theme.colors.white}
          ariaLabel={
            isLeasingJourneySelected
              ? isUIv2
                ? t("price.monthlyPayments")
                : t("price.monthly")
              : isUIv2
              ? t("price.singlePayment")
              : t("price.cash")
          }
          isHorizontal
        />
        <SwitchLabelWrapper>
          <SwitchLabel
            as="label"
            isActive={isCashJourneySelected}
            onClick={toggleJourneyType}
            isHorizontal
          >
            {isUIv2 ? t("price.singlePayment") : t("price.cash")}
          </SwitchLabel>
        </SwitchLabelWrapper>
      </HorizontalOptionSwitcherWrapper>
    );
  }

  return (
    <OptionSwitcherWrapper
      className={className}
      data-id="price-switcher-input-toggle"
      isJourneySwitchActive={isJourneySwitchActive}
    >
      <Switch
        checked={isCashJourneySelected}
        onChange={toggleJourneyType}
        switchBorderColor={theme.colors.white}
        ariaLabel={
          isLeasingJourneySelected
            ? isUIv2
              ? t("price.monthlyPayments")
              : t("price.monthly")
            : isUIv2
            ? t("price.singlePayment")
            : t("price.cash")
        }
      />

      <SwitchLabelContainer>
        <SwitchLabel
          as="label"
          isActive={isLeasingJourneySelected}
          onClick={toggleJourneyType}
          isUIv2={isUIv2}
        >
          {isUIv2 ? t("price.monthlyPayments") : t("price.monthly")}
        </SwitchLabel>
        <SwitchLabel
          as="label"
          isActive={isCashJourneySelected}
          onClick={toggleJourneyType}
          isUIv2={isUIv2}
        >
          {isUIv2 ? t("price.singlePayment") : t("price.cash")}
        </SwitchLabel>
      </SwitchLabelContainer>
    </OptionSwitcherWrapper>
  );
};

JourneySwitch.propTypes = {
  isHorizontal: bool,
  isTextVersion: bool,
  className: string,
  isUIv2: bool,
};

export default JourneySwitch;
