import { useMyAccountSettingsSelector } from "@redux/reducers/appConfig";
import { useIsB2BSelected } from "@hooks/useIsB2BSelected";

export const useIsFree2MoveEnabled = () => {
  const isB2BSelected = useIsB2BSelected();
  const { free2moveService } = useMyAccountSettingsSelector();

  return isB2BSelected && free2moveService.b2b
    ? free2moveService.b2b.enabled
    : free2moveService.enabled;
};
