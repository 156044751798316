import React from "react";
import styled, { css } from "styled-components";
import { bool } from "prop-types";

import Typography from "@components/shared/Typography";

import { useTranslation } from "@lib/i18n";
import { useOpenFinanceWidget } from "@hooks/useOpenFinanceWidget";
import {
  color,
  font,
  fontWeight,
  desktop,
  tabletLarge,
} from "@components/shared/utils";
import CalculatorIcon from "@components/shared/Icons/Calculator";
import { getIsCountry, COUNTRIES } from "@lib/getIsCountry";

const StyledButton = styled.button`
  align-items: center;
  background: none;
  border: none;
  outline: none;
  display: flex;

  &:hover {
    cursor: pointer;
  }

  ${desktop`
    width: 9.375rem;
  `}

  ${({ isBasketUi }) =>
    isBasketUi &&
    css`
      align-items: flex-end;
      height: 2.5rem;

      ${() =>
        !getIsCountry(COUNTRIES.GB) &&
        css`
          position: absolute;
          height: 1.125rem;
          bottom: 0.5rem;
          right: 0;
        `}

      ${desktop`
        width: 16.5rem;
      `}
    `}
`;

const AdditionalText = styled(Typography)`
  font-family: ${font("citroen")};
  font-weight: ${fontWeight("bold")};
  letter-spacing: 0.4px;
  font-size: 0.83rem;
  color: ${color("white")};
  text-align: left;
  cursor: default;
  text-decoration: none;
  text-transform: uppercase;
  display: none;
  width: 7.5rem;
  padding-left: 0.5rem;
  white-space: unset;

  &:hover {
    cursor: pointer;
  }

  ${tabletLarge`
    display: block;
  `}

  ${desktop`
    display: block;
    padding-left: 0.8rem;
  `}

  ${({ isBasketUi }) =>
    (isBasketUi || getIsCountry(COUNTRIES.GB)) &&
    css`
      display: block;
      white-space: nowrap;
      width: auto;

      ${tabletLarge`
        white-space: unset;
        padding-left: 0.5rem;
        ${({ isBasketUi }) =>
          !isBasketUi &&
          css`
            white-space: unset;
          `}
      `}
    `}
`;

const FinanceWidgetButton = ({ isBasketUi }) => {
  const openFinanceWidget = useOpenFinanceWidget();
  const { t } = useTranslation();

  return (
    <StyledButton
      isBasketUi={isBasketUi}
      onClick={openFinanceWidget}
      data-id="finance-icon-container"
    >
      <CalculatorIcon width="24px" height="24px" fill="white" />
      <AdditionalText isBasketUi={isBasketUi}>
        {t("price.financeWidgetFinance")}
      </AdditionalText>
    </StyledButton>
  );
};

FinanceWidgetButton.propTypes = {
  isBasketUi: bool,
};

export default FinanceWidgetButton;
