import React from "react";
import styled, { css } from "styled-components";
import { useTranslation } from "@lib/i18n";
import { bool, number } from "prop-types";

import Typography from "@components/shared/Typography";

import { useGetFormattedPrice } from "@hooks/useGetFormattedPrice";
import { financeGatewayTeaserType } from "@types";
import { useIsLOAPriceBreakdownRequestErrorSelector } from "@redux/reducers/requests";
import { useIsB2BSelected } from "@hooks/useIsB2BSelected";
import { useFinanceGatewayDataParser } from "@hooks/useFinanceGatewayDataParser";

import {
  color,
  tablet,
  desktop,
  fontWeight,
  tabletLarge,
  fontSize,
  font,
} from "@components/shared/utils";

const PRICE_TEXT_SIZE = 3;
const calculateFontSize = displaySize => `${displaySize * 0.45}rem`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 100%;
  justify-content: center;
  padding-right: 1rem;
  z-index: 1;
  color: ${color("white")};
  min-width: 12rem;
  margin-right: 0.5rem;
  position: relative;

${({ withFinanceWidgetButton }) =>
  withFinanceWidgetButton &&
  css`
    margin-right: 0.5rem;

    ${tablet`
      margin-right: 1.5rem;
    `}

    &:after {
      background: ${color("grey")};
      content: "";
      height: 60%;
      position: absolute;
      right: -0.25rem;
      top: 20%;
      width: 1px;

      ${tablet`
        right: -0.75rem;
      `}
    }
  `}

  ${tablet`
    padding: 1rem;
  `}

  ${tabletLarge`
    padding: 0.4rem;
  `}

  ${desktop`
    padding: 1rem 0 1rem 1rem;
    min-width: 14rem;
  `}
`;

const StyledTypography = styled(Typography)`
  color: ${color("white")};
`;

const StyledPrice = styled.span`
  font-size: ${({ displaySize = 1 }) => calculateFontSize(displaySize)};
  font-weight: ${fontWeight("bold")};
`;

const StyledSup = styled.sup`
  font-size: ${({ displaySize = 1 }) => calculateFontSize(displaySize)};
`;

const StyledStar = styled.sup`
  font-size: ${({ displaySize = 1 }) => calculateFontSize(displaySize)};
  font-weight: ${fontWeight("bold")};
`;

const PrimaryTextTypography = styled(Typography)`
  color: ${color("white")};
  display: inline;
  font-size: ${({ displaySize = 1 }) => calculateFontSize(displaySize)};
  font-weight: ${fontWeight("bold")};
  text-align: right;
`;

const ErrorMessage = styled(Typography)`
  color: ${color("white")};
  font-family: ${font("citroen")};
  font-size: ${fontSize("md")};
  font-weight: ${fontWeight("bold")};
  margin: auto;
  max-width: 20rem;
  padding: 0 1rem;
  text-align: center;
`;

const LOAPriceDetail = ({ withFinanceWidgetButton, amount, teaser }) => {
  const { t } = useTranslation();

  const isLOAPriceBreakdownRequestError = useIsLOAPriceBreakdownRequestErrorSelector();
  const isB2BSelected = useIsB2BSelected();
  const financeGatewayDataParser = useFinanceGatewayDataParser();

  const getFormattedPrice = useGetFormattedPrice();

  if (!teaser || !amount) {
    return <ErrorMessage>{t("financeDataErrorMessage")}</ErrorMessage>;
  }

  return (
    <Container
      withFinanceWidgetButton={withFinanceWidgetButton}
      data-id="price-switcher-price-container"
    >
      {isLOAPriceBreakdownRequestError ? (
        <ErrorMessage>{t("financeDataErrorMessage")}</ErrorMessage>
      ) : (
        <>
          <div>
            <StyledTypography>
              <StyledPrice
                displaySize={PRICE_TEXT_SIZE}
                data-id="price-switcher-formatted-price"
              >
                {getFormattedPrice(amount)}
              </StyledPrice>
              <StyledSup displaySize={PRICE_TEXT_SIZE / 2}>
                {" "}
                {isB2BSelected
                  ? t("price.b2b.month")
                  : t("price.b2c.month")}{" "}
              </StyledSup>
              <StyledStar displaySize={PRICE_TEXT_SIZE}>*</StyledStar>
            </StyledTypography>
          </div>

          {financeGatewayDataParser("primarytext", teaser).map(
            ({ parsedText, targetItem }, index) => (
              <PrimaryTextTypography
                key={index}
                displaySize={targetItem.style?.displaySize}
              >
                {parsedText}
              </PrimaryTextTypography>
            )
          )}
        </>
      )}
    </Container>
  );
};

LOAPriceDetail.propTypes = {
  withFinanceWidgetButton: bool,
  amount: number,
  teaser: financeGatewayTeaserType,
};

export default LOAPriceDetail;
