import React from "react";
import { bool } from "prop-types";
import styled from "styled-components";

import { usePrimaryColor } from "@hooks/usePrimaryColor";
import { prop, desktop, largeMobile } from "@components/shared/utils";

const Container = styled.div`
  height: 100%;
  overflow: hidden;

  div {
    width: 5rem;
    height: 1rem;
    background: ${prop("primaryColor")};
    transform: rotate(-45deg) skew(-45deg);
    position: relative;
    right: -1rem;
    top: 1.6rem;
    margin-bottom: 2.4rem;
  }

  ${largeMobile`
    display: block;
  `}

  ${desktop`
    right: 0;
  `}
`;

const Parallelograms = ({ ...props }) => {
  const primaryColor = usePrimaryColor();

  return (
    <Container primaryColor={primaryColor} {...props}>
      <div />
      <div />
    </Container>
  );
};

Parallelograms.propTypes = {
  fullWidth: bool,
};

export default Parallelograms;
