import React from "react";
import styled from "styled-components";

import AnimatedTuneIcon from "@components/shared/Icons/AnimatedTune";
import Typography from "@components/shared/Typography";

import { useTranslation } from "@lib/i18n";
import { useOpenFinanceWidget } from "@hooks/useOpenFinanceWidget";
import { color, font, fontWeight, desktop } from "@components/shared/utils";

const StyledAnimatedTuneIcon = styled(AnimatedTuneIcon)`
  color: ${color("white")};
`;

const StyledButton = styled.button`
  align-items: center;
  background: none;
  border: none;
  outline: none;
  display: flex;

  &:hover {
    cursor: pointer;
  }
`;

const AdditionalText = styled(Typography)`
  font-family: ${font("citroen")};
  font-weight: ${fontWeight("bolder")};
  letter-spacing: 0.6px;
  font-size: 0.7rem;
  color: ${color("white")};
  text-align: left;
  cursor: default;
  text-decoration: underline;
  text-transform: uppercase;
  display: none;

  &:hover {
    cursor: pointer;
  }

  ${desktop`
    display: block;
    padding-left: 0.8rem;
  `}
`;

const FinanceWidgetButton = () => {
  const openFinanceWidget = useOpenFinanceWidget();
  const { t } = useTranslation();

  return (
    <StyledButton
      onClick={openFinanceWidget}
      data-id="finance-icon-container"
      aria-label={t("price.financeWidgetText")}
    >
      <StyledAnimatedTuneIcon height="48px" width="48px" />
      <AdditionalText>{t("price.financeWidgetText")}</AdditionalText>
    </StyledButton>
  );
};

export default FinanceWidgetButton;
