import React from "react";
import styled, { useTheme } from "styled-components";
import { bool, func, string } from "prop-types";

import { prop, color } from "./utils";

import { withTabNavigation } from "@hoc/withTabNavigation";

const Wrapper = styled.div`
  min-width: 350px;
`;

const Body = styled.div`
  position: relative;
  /* width: auto;
  height: 100%;
  overflow: hidden; */
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.1s;
`;

const Label = styled.label`
  height: 2.8rem;
  border-radius: 100px;

  width: 100%;
  background-color: ${prop("switchBackgroundColor")};
  border: 4px solid ${prop("switchBackgroundColor")};
  border-radius: 100px;
  position: relative;
  margin: 1.8rem 0 4rem 0;
  cursor: pointer;
`;

const Names = styled.div`
  font-size: 0.75em;
  font-weight: 600;
  line-height: 0.75em;
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: space-around;
  align-items: center;
  user-select: none;
  height: 100%;
`;

const Toggle = styled.div`
  height: 100%;
  border-radius: 100px;

  position: absolute;
  width: 50%;
  background: ${({ primaryColor }) => primaryColor || color("white")};
  transition: transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);

  transform: ${({ isChecked }) =>
    isChecked ? "translateX(100%)" : "translateX(0)"};
`;

const HiddenCheckbox = styled.input`
  width: 0; // "display: none;" is not used because of accessibility report
  opacity: 0;
`;

const Option = withTabNavigation(styled.p`
  transition: color 0.3s;
  text-transform: capitalize;
  background-color: ${({ isSelected }) =>
    isSelected
      ? color("white")
      : "unset"}; // APP-20606 accessibility violation fix
  color: ${({ isSelected, primaryColor }) =>
    isSelected
      ? "black"
      : primaryColor || color("white")}; //prop("switchBackgroundColor")
`);

const Switch = ({
  checked = false,
  onChange,
  primaryColor,
  switchBackgroundColor,
  switchBorderColor = "white",
  selectedText,
  unselectedText,
  ...props
}) => {
  const theme = useTheme();

  const onCh = (...props) => {
    console.log(props);
    onChange(props);
  };

  return (
    <Wrapper>
      <HiddenCheckbox
        id="switch"
        type="checkbox"
        value={checked}
        onChange={onCh}
        {...props}
        tabIndex={-1}
      />
      <Body>
        <Label
          htmlFor="switch"
          switchBorderColor={
            switchBorderColor ? switchBorderColor : theme.colors.transparent
          }
          switchBackgroundColor={
            switchBackgroundColor
              ? switchBackgroundColor
              : theme.colors.darkGrey
          }
        >
          <Toggle isChecked={checked} primaryColor={primaryColor} />
          <Names>
            <Option isSelected={!checked} primaryColor={primaryColor}>
              {selectedText}
            </Option>
            <Option isSelected={checked} primaryColor={primaryColor}>
              {unselectedText}
            </Option>
          </Names>
        </Label>
      </Body>
    </Wrapper>
  );
};

Switch.propTypes = {
  checked: bool.isRequired,
  onChange: func.isRequired,
  primaryColor: string,
  isHorizontal: bool,
  switchBackgroundColor: string,
  switchBorderColor: string,
  selectedText: string,
  unselectedText: string,
};

export default Switch;
