import { useRouter } from "next/router";

import { priceAlgorithm } from "@lib/priceAlgorithm";
import { useFormattedDeal } from "@hooks/useFormattedDeal";
import { getIsDeliveryPriceIncluded } from "@lib/getIsDeliveryPriceIncluded";
import { getIsRegistrationDiscountApplied } from "@shared/v2/lib/getIsRegistrationDiscountApplied";
import { useIsOfferSummaryPage } from "@hooks/useIsOfferSummaryPage";
import { useAppConfigSelector } from "@redux/reducers/appConfig";

import {
  useCurrentDealSelector,
  useIsDealPriceLockedSelector,
  useLastCtaSelector,
  useDealSelector,
} from "@redux/reducers/deal";

const useCashVehiclePrice = isPromoCodeApplicable => {
  const router = useRouter();
  const isPriceLocked = useIsDealPriceLockedSelector();
  const currentDeal = useCurrentDealSelector();
  const lastCta = useLastCtaSelector();
  const formatDeal = useFormattedDeal();
  const isOfferSummaryPage = useIsOfferSummaryPage();
  const reduxDeal = useDealSelector();
  const appConfig = useAppConfigSelector();

  if (isOfferSummaryPage && isPriceLocked) {
    const formattedDeal = formatDeal(currentDeal);

    const isRegistrationDiscountApplied = getIsRegistrationDiscountApplied(
      router.pathname,
      formattedDeal.lastCta
    );

    const isDeliveryIncluded = getIsDeliveryPriceIncluded(
      router.pathname,
      formattedDeal.lastCta
    );

    const { netPrice } = priceAlgorithm.getTotalPrice(
      formattedDeal,
      {
        car: { netPrice: currentDeal.extendedVehicleInfo.netPrice },
        configuration: currentDeal.configuration,
      },
      {
        isRegistrationIncluded: isRegistrationDiscountApplied,
        isDeliveryIncluded,
        isPromoCodeApplicable,
      }
    );

    return netPrice;
  }

  const isRegistrationDiscountApplied = getIsRegistrationDiscountApplied(
    router.pathname,
    lastCta
  );

  const { netPrice } = priceAlgorithm.getTotalPrice(reduxDeal, appConfig, {
    isRegistrationIncluded: isRegistrationDiscountApplied,
    isDeliveryIncluded: true,
    isPromoCodeApplicable,
  });

  return netPrice;
};

export { useCashVehiclePrice };
