import React from "react";
import styled, { useTheme } from "styled-components";
import { bool, func, string } from "prop-types";

import { prop, color } from "./utils";

const StyledLabel = styled.label`
  cursor: pointer;
  display: block;
  position: relative;
  max-height: 3rem;
  max-width: 1.5rem;
  transform: ${({ isHorizontal }) => isHorizontal && "rotate(-90deg)"};
`;

const StyledInput = styled.input`
  appearance: none;
  background: ${prop("switchBackgroundColor")};
  border-radius: 20px;
  box-shadow: 0px 0px 0px 1.5px ${prop("switchBorderColor")};
  cursor: pointer;
  height: 3rem;
  margin: 0;
  width: 1.5rem;
  transition: 0.1s linear box-shadow;

  &:focus {
    outline: none;
  }

  &:hover,
  &:focus {
    box-shadow: 0px 0px 4px 3px ${prop("switchBorderColor")};
  }
`;

const Slider = styled.span`
  background: ${({ primaryColor }) => primaryColor || color("white")};
  border-radius: 50%;
  height: 1.2rem;
  position: absolute;
  left: 0;
  top: 50%;
  transition: transform 0.4s;
  width: 1.2rem;
  transform: ${({ checked }) =>
    checked ? "translate(12%, 0.1rem)" : "translate(12%, -1.3rem)"};
`;

const Switch = ({
  checked,
  onChange,
  primaryColor,
  isHorizontal,
  switchBackgroundColor,
  switchBorderColor,
  ariaLabel,
  ...props
}) => {
  const theme = useTheme();

  return (
    <StyledLabel
      isHorizontal={isHorizontal}
      data-id="journey-switch"
      aria-label={ariaLabel}
    >
      <StyledInput
        switchBackgroundColor={
          switchBackgroundColor ? switchBackgroundColor : theme.colors.darkGrey
        }
        switchBorderColor={
          switchBorderColor ? switchBorderColor : theme.colors.transparent
        }
        type="checkbox"
        checked={checked}
        onChange={onChange}
        {...props}
        aria-label={ariaLabel}
      />
      <Slider checked={checked} primaryColor={primaryColor} />
    </StyledLabel>
  );
};

Switch.propTypes = {
  checked: bool.isRequired,
  onChange: func.isRequired,
  primaryColor: string,
  isHorizontal: bool,
  switchBackgroundColor: string,
  switchBorderColor: string,
  ariaLabel: string,
};

export default Switch;
