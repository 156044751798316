import { useGetFormattedPrice } from "@hooks/useGetFormattedPrice";

const DEFAULT_DELIMITER = ".";
const TARGET_DELIMITER = ",";
const PRIMARY_PRICE_KEY = "amountInclTax";

export const useFinanceGatewayDataParser = (config = {}) => {
  const {
    inputDelimiter = DEFAULT_DELIMITER,
    outputDelimiter = TARGET_DELIMITER,
    primaryPriceKey = PRIMARY_PRICE_KEY,
  } = config;

  const getFormattedPrice = useGetFormattedPrice();

  return (key, teaser) => {
    const { guide, data } = teaser;

    const target = teaser[key];
    const targetItems = Array.isArray(target) ? target : [target];

    return targetItems.map(item => {
      let result = item.text;

      if (item.arguments?.length > 0) {
        result = item.arguments.reduce((acc, cur, index) => {
          const isPercentage = guide.percentages.includes(cur);
          const suffix = isPercentage ? "%" : "";

          let value = data[cur];

          if (guide.amountInclExclTax?.includes(cur)) {
            value = getFormattedPrice(Math.ceil(value[primaryPriceKey]));
          }

          if (guide.amounts?.includes(cur)) {
            value = getFormattedPrice(Math.ceil(value));
          }

          if (isPercentage) {
            value = String(value).replace(inputDelimiter, outputDelimiter);
          }

          return acc.replace(`{${index}}`, value + suffix);
        }, result);
      }

      return {
        parsedText: result,
        targetItem: item,
      };
    });
  };
};
