const { bool } = require("prop-types");
import { useIsB2BSelected } from "@hooks/useIsB2BSelected";

const useIsJourneySwitchActive = ({ status }) => {
  const isB2BSelected = useIsB2BSelected();

  if (typeof status === "boolean") {
    return status;
  } else if (Array.isArray(status) && status.length === 4) {
    const [
      b2cCashActive,
      b2cFinanceActive,
      b2bCashActive,
      b2bFinanceActive,
    ] = status;
    return isB2BSelected
      ? b2bCashActive && b2bFinanceActive
      : b2cCashActive && b2cFinanceActive;
  }

  return false;
};

useIsJourneySwitchActive.propTypes = {
  isB2BSelected: bool,
};

module.exports = { useIsJourneySwitchActive };
